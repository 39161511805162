<!-- 新冠特异性T细胞免疫指纹 -->
<template>
  <div style="padding: 20px 24px 40px 24px">
    <div class="resultItem">
      <div style="margin-bottom: 16px">
        <span class="globalText middle" style="line-height: 30px">共检出新型冠状病毒（SARS-CoV-2）特异性TCR克隆<span
            style="color: #865bff; margin: 0 5px">{{ covid19Num }}</span>个
        </span>
      </div>
      <div>
        <span class="globalText middle" style="line-height: 30px">即体内存在<span style="color: #865bff; margin: 0 5px">{{
          covid19Num
        }}</span>种新冠特异性T细胞</span>
      </div>
    </div>

    <div class="globalTitle" style="margin: 30px 0 12px 0; color: #926bff">
      新冠特异性TCR信息
    </div>
    <div class="star">
      <span>
        <span class="xing">*</span>注：克隆频率为检出的新冠特异性TCR克隆数占总检出TCR克隆数的比例。表格仅展示top10新冠特异性TCR克隆。
      </span>
    </div>
    <div class="globalText">
      <div style="display: flex">
        <div class="top" style="width: 56%">TCR克隆序列(氨基酸)</div>
        <div class="top" style="width: 44%">克隆频率</div>
      </div>
      <div style="display: flex" v-for="(item, index) in topList" :key="index">
        <div class="content" style="width: 56%; border-right: 0">
          {{ item.CDR3 }}
        </div>
        <div class="content" style="width: 44%">{{ item.freq }}</div>
      </div>
    </div>

    <div class="globalText more" v-if="isShowMore" @click="showMore()">
      {{ tip }}
      <div :class="isShow ? 'scroll' : 'noscroll'" style="margin-left: 3px">
        <van-icon name="arrow-down" color="#999999" size="14px" />
      </div>
    </div>

    <div class="globalTitle" style="margin: 30px 0 12px 0; color: #926bff">
      指标解释
    </div>
    <div class="globalText">
      <p style="margin-bottom: 14px">
        新型冠状病毒（SARS-CoV-2）感染或接种疫苗后会引起不同的适应性免疫反应，从而在体内产生不同T细胞和B细胞克隆。B细胞介导的体液免疫可通过对抗体检测进行评价。而T细胞介导的细胞免疫，可以通过对新冠病毒特异性T细胞的TCR分析进行评价。使用免疫组库测序技术，对新冠感染后的T细胞克隆性进行分析，并将测序结果与新冠相关TCR数据库进行比对，注释其与新冠感染相关的个性化TCR特征，最终将比对序列及占比进行展示。
      </p>
      新冠特异性T细胞存在，标志着正在或发生过新冠感染（或接种过疫苗），相较于抗体，新冠特异性T细胞在体内存留时间更长。有研究表明感染冠状病毒后，特异性T细胞可持续存在2年以上，提示可能为机体带来更持久的免疫能力。
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      topList: [],
      isShow: false,  //序列信息折叠
      tip: '查看更多',
      covid19Num: 0,
      covid19TCR: [],
      isShowMore: false,  //是否展示更多按钮
    }
  },
  created() {
  },
  mounted() {
    // 置顶
    window.scroll({ top: 0, left: 0 });

    let data = JSON.parse(localStorage.getItem('report'))
    this.covid19Num = data.covid19Num
    this.covid19TCR = data.covid19TCR

    if (this.covid19TCR.length <= 4) {
      this.topList = this.covid19TCR
      this.isShowMore = false
    } else {
      // 取前四位
      this.topList = this.covid19TCR.slice(0, 4)
      this.isShowMore = true
    }

  },
  computed: {
  },
  methods: {
    showMore() {
      // 序列信息展开 / 或折叠
      if (this.topList.length == this.covid19TCR.length) {
        let topList = this.topList
        this.topList = topList.splice(0, 4)
        this.isShow = false;
        this.tip = '查看更多'
      } else {
        let topList = this.topList;
        if (topList.length < 4) {
          topList = topList.concat(this.covid19TCR.slice(topList.length, 4))
        } else {
          topList = topList.concat(this.covid19TCR.slice(topList.length, 4 + topList.length))
        }
        if (topList.length == this.covid19TCR.length) {
          this.tip = '收起'
          this.isShow = true
        } else {
          this.isShow = false;
          this.tip = '查看更多'
        }
        this.topList = topList
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import url(../../../static/css/global.css);

.resultItem {
  width: 100%;
  padding: 20px 16px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.resultButton {
  width: 295px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 24px;
  box-sizing: border-box;

  .btnBgc {
    position: absolute;
    width: 295px;
    height: 40px;
    top: 0;
    left: 0;
  }
}

.top {
  background: #926bff;
  border-radius: 8px 8px 0px 0px;
  padding: 9px 12px;
  box-sizing: border-box;
  color: #ffffff;
}

.content {
  width: 183px;
  background: #fafcff;
  padding: 9px 12px;
  box-sizing: border-box;
  border: 1px solid #e1e3e6;
  margin-top: -1px;
}

.scroll {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.noscroll {
  transform: rotate(0);
  transition: transform 0.3s;
}

.transHeight {
  transition: height 0.3s;
}

.star {
  font-size: 12px;
  color: #e02020;
  line-height: 17px;
  margin: 4px 0 12px 0;
}

.more {
  color: #999999;
  text-align: center;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
}
</style>
